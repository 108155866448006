/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import Button from './Button'

const SocialLoginButton: React.FunctionComponent<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ ...restProps }) => {
  return (
    <Button
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: 'white',
        background: '#3771c8',
        p: '0.8rem',
        '&:hover': {
          bg: '#0b182a',
        },
      }}
      {...restProps}
    />
  )
}

export default SocialLoginButton
