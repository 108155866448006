import * as React from 'react'

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number
}

const Twitter: React.FunctionComponent<Props> = ({
  size = 16,
  ...restProps
}) => {
  const aspectRatio = 98 / 120
  const height = size * aspectRatio
  return (
    <svg width={size} height={height} viewBox='0 0 120 98' {...restProps}>
      <title>Twitter</title>
      <g fill='none' fillRule='evenodd'>
        <path d='M0-13h120v120H0V-13z' />
        <path
          d='M120 11.8777c-4.4152 1.9581-9.1601 3.2815-14.1402 3.8767 5.0832-3.0471 8.9867-7.8719 10.825-13.6206-4.7568 2.821-10.0257 4.87-15.6336 5.9744C96.5601 3.3233 90.1617.3333 83.081.3333c-13.5969 0-24.6202 11.023-24.6202 24.619 0 1.9295.2181 3.8086.6377 5.6104-20.4613-1.0267-38.602-10.828-50.7446-25.7228-2.1192 3.636-3.3333 7.8652-3.3333 12.377 0 8.5412 4.3468 16.0768 10.9527 20.4916-4.036-.1277-7.8319-1.2351-11.1515-3.0791-.0018.1027-.0018.2059-.0018.3094 0 11.9285 8.4864 21.8791 19.749 24.1405-2.0661.5627-4.241.8634-6.4866.8634-1.5863 0-3.1287-.154-4.6316-.4416 3.1326 9.7812 12.2247 16.899 22.9979 17.0978-8.4258 6.6029-19.0414 10.5388-30.5757 10.5388-1.987 0-3.9469-.1163-5.8729-.3437 10.8952 6.9852 23.8363 11.0607 37.7395 11.0607 45.2839 0 70.0467-37.5143 70.0467-70.0476 0-1.0675-.0237-2.1293-.071-3.185 4.8098-3.471 8.984-7.8073 12.2848-12.7444'
          fill='#FFF'
        />
      </g>
    </svg>
  )
}

export default Twitter
