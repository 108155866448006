import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import config from 'gatsby-theme-firebase/src/firebase/config'

const isBrowser = typeof window !== 'undefined'

// initialize Firebase if not already running
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

// initialize Firebase Authentication and get a reference to the service
let auth: firebase.auth.Auth
if (isBrowser) {
  auth = firebase.auth()
  // use emulator if not on production
  if (process.env.NODE_ENV !== 'production') {
    auth.useEmulator('http://localhost:9099')
  }
}
// initialize Firebase Firestore and get a reference to the service
const firestore = firebase.firestore()
// initialize Firebase Storage and get a reference to the service
const storage = firebase.storage()

// use emulator if not on production
if (process.env.NODE_ENV !== 'production') {
  firestore.useEmulator('localhost', 8080)
  storage.useEmulator('localhost', 9199)
}

export { auth, firestore, storage }
export default firebase
