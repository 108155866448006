import React, { useState } from 'react'
import Head from './head'
import '../language/i18n'
import LanguageSwitcher from '../language/languageSwitcher'
import { useStaticQuery, graphql } from "gatsby"
import { navigate, Link } from 'gatsby'
import { auth, useAuth } from 'gatsby-theme-firebase'
import { Container, Navbar, Nav, NavDropdown, Toast } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faSignOutAlt, faMapMarked, faUser, faCog, faList, faWifi, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { Detector } from 'react-detect-offline'
import i18next from 'i18next'

export default function Layout({children}) {

  const data = useStaticQuery(graphql`
    query NavQuery {
      nav: allStoryblokEntry(filter: {slug: {eq: "horizontal-navigation-bar"}})  {
        edges {
          node {
            content
          }
        }
      }
    }
  `)

  // l10n
  let storyPage

  if (i18next.language === 'cs') {
    storyPage = JSON.parse(data.nav.edges[0].node.content)
  } else {
    storyPage = JSON.parse(data.nav.edges[1].node.content)
  }

  function menuSignOut() {
    auth.signOut()
    navigate('/')
  }

  function menuSignIn() {
    navigate('/login')
  }

  const { isLoading, isLoggedIn, profile } = useAuth()

  const [ connectionControl, setConnectionControl] = useState(false)
  const polling = {
    url: 'https://ipv4.icanhazip.com',
  }

  if (isLoading) {
    return null
  }

  return (
    <div>
      <Head />
      <Navbar expand="xl">
        <Container>
          <Navbar.Brand href="/">
            <img src={'../../images/pinavo_maps_logo.svg'} width="auto" height="auto" alt="Developed for a better future."/>
          </Navbar.Brand>
          <Detector
            polling={polling}
            onChange={() => setConnectionControl(true)}
            render={({ online }) => (
              <>
                <Nav.Link className="ms-auto">
                  <span role="menuitem" onClick={() => { setConnectionControl(true) }}><FontAwesomeIcon icon={faWifi} color={online ? '#33ba33' : 'red'}/></span>
                </Nav.Link>
                <Toast
                  onClose={() => setConnectionControl(false)}
                  delay={4000}
                  show={connectionControl}
                  className='closeToast'
                  autohide
                >
                  <Toast.Header>
                    <strong className="me-auto">Connection control</strong>
                    <small>info</small>
                  </Toast.Header>
                  <Toast.Body>You are currently <b>{online ? 'online' : 'offline'}</b>.</Toast.Body>
                </Toast>
              </>
            )}
          />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/map"><FontAwesomeIcon icon={faMapMarked} color="#3771c8"/> {storyPage.blocks[0].blocks[0].title}</Nav.Link>
              <Nav.Link as={Link} to='/get-a-bottle'>{storyPage.blocks[0].blocks[1].title}</Nav.Link>
              <Nav.Link href="https://medium.com/pinavo">{storyPage.blocks[0].blocks[2].title}</Nav.Link>
              {/* <Nav.Link href="/shallop">Shallop</Nav.Link>
              <Nav.Link href="/larboard">Larboard</Nav.Link> */}
              <Nav.Link href="/follow-us">{storyPage.blocks[0].blocks[3].title}</Nav.Link>
              <Nav.Link href="/about">{storyPage.blocks[0].blocks[4].title}</Nav.Link>
            </Nav>
            <Nav>
              {isLoggedIn ? (
                <>
                  <Nav.Link href="/map/list"><FontAwesomeIcon icon={faList} color="#3771c8"/> {storyPage.blocks[1].blocks[0].title}</Nav.Link>
                  <NavDropdown title={<span><FontAwesomeIcon icon={faUser} color="#fff"/><font style={{color: '#fff'}}> {profile.displayName}</font></span>} align="end">
                    <NavDropdown.Item href="/profile"><FontAwesomeIcon icon={faCog} color="#212529"/>&nbsp;{storyPage.blocks[1].blocks[1].title}</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => { menuSignOut() }}><FontAwesomeIcon icon={faSignOutAlt} color="#212529"/>&nbsp;{storyPage.blocks[1].blocks[2].title}</NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <Nav.Link>
                  <span role="menuitem" tabIndex={0} onClick={() => { menuSignIn() }}><FontAwesomeIcon icon={faSignInAlt} color="#fff"/>&nbsp;{storyPage.blocks[1].blocks[3].title}</span>
                </Nav.Link>
              )}
            </Nav>
            <Nav className="language-switcher">
              <LanguageSwitcher />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {children}
    </div>
  )
}
