import React, { useState } from "react"
import { Form } from 'react-bootstrap'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const LanguageSwitcher = () => {

  const { i18n } = useTranslation()

  const handleLangChange = e => {
    const lang = e.target.value
    i18n.changeLanguage(lang)
    window.location.reload()
  }

  return (
    <Form.Select onChange={handleLangChange} value={i18n.language} aria-label="Language switcher">
      <option value="cs">Czech - Čeština</option>
      <option value="en">English</option>
    </Form.Select>
  )
}

export default LanguageSwitcher;